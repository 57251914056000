import React, { Component } from 'react';
import { Popup } from 'react-leaflet';
import "./BlueSignPopup.css";
import {
    translate
} from 'react-switch-lang';

class BlueSignPopup extends Component {

    render() {
        const blueSign = this.props.blueSign;
        const titleKey = "blueSigns." + blueSign.id + ".title";
        const subHeaderKey = "blueSigns." + blueSign.id + ".subHeader";
        const descriptionKey = "blueSigns." + blueSign.id + ".description";
        const t = this.props.t;
        const description = t(descriptionKey);
        const splitDescription = description.split("\n");
        const sections = splitDescription.map((splitPart) =>
          <div><div>{splitPart}</div><br /></div>
        );

        return (
            <Popup className="blue-sign-popup">
                {
                    blueSign.thumbnail &&
                    <div className="thumbnail">
                        <img src={blueSign.thumbnail} alt="House"/>
                        <br />
                    </div>
                }
                <div>
                    <span className="header">{t(titleKey)}</span>
                {
                    t(subHeaderKey) &&
                        <div>
                            <span className="subHeader">{t(subHeaderKey)}</span>
                        </div>
                    }
                    </div>
                <br />
                <div className="text">
                    {sections}
                    <span className="attribution">Knabens Venner</span>
                </div>
                <div className="appendum">
                </div>
            </Popup>
        );
    }
}

export default translate(BlueSignPopup);