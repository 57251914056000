import React, { Component } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import BlueSignPopup from './BlueSignPopup.js'
import LanguageSelector from './LanguageSelector.js'
import "./KnabenMap.css";
import LocateControl from './LocateControl.js'
const tilesAttribution = '<a href="http://www.kartverket.no/">Kartverket</a>';

/*
Ex. call getSize() on Leaflet Map object:
    const leafletMap = this.map.leafletElement;
    const mapSize = leafletMap.getSize();
*/
export default class KnabenMap extends Component {

    render() {
        const tiles = this.props.tiles;
        let tilesUrl = "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart_graatone&zoom={z}&x={x}&y={y}";
        if (tiles === "topo") {
            tilesUrl = "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}";
        }

        const L = require('leaflet');
        const iconHouse = L.icon({
          iconUrl: require('./house.svg'),
          iconSize: [32,32],
          iconAnchor: [16,16],
        });
        const iconInfo = L.icon({
          iconUrl: require('./blue-info-circle.svg'),
          iconSize: [18, 18],
          iconAnchor: [9, 9],
        });

        const icons = {
            house: iconHouse,
            info: iconInfo
        }

        const locateOptions = {
          position: 'bottomright',
          strings: {
              title: 'Se hvor du er nå.'
          },
          drawCircle: false,
          icon: 'fa fa-crosshairs',
          onActivate: () => {} // callback before engine starts retrieving locations
        }
        const blueSigns = this.props.blueSigns;
        const zoom = this.props.zoom;
        return (
            <div>
                <Map center={[58.6624039, 7.0695606]} zoom={zoom} maxZoom={18}  ref={(ref) => { this.map = ref; }}>
                <LocateControl options={locateOptions}/>
                    <TileLayer attribution={tilesAttribution} url={tilesUrl} />
                    {blueSigns.map((blueSign, idx) =>
                          <Marker key={`marker-${idx}`} position={blueSign.position} icon={icons[blueSign.type] ? icons[blueSign.type] : iconInfo}>
                              <BlueSignPopup blueSign={blueSign} />
                          </Marker>
                    )}
                    <LanguageSelector selectedLanguage={this.props.selectedLanguage} setLanguageCallback={this.props.setLanguageCallback}/>
                </Map>

            </div>
        );
    }
}